
import { defineComponent, ref, onMounted, computed } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute } from "vue-router";
import moment from "moment";
import Datepicker from "vue3-datepicker";
import router from "@/router";
import store from "@/store";
import {
  addDesignMaterial,
  getDesignMaterialById,
  designMaterialObjClone,
} from "@/core/data/designMaterial";
import MaterialMultiselect from "@/components/all-multiselect/material-multiselect.vue";
import { getError, getSuccess } from "@/core/helpers/utils";
export default defineComponent({
  name: "design-material-form",
  components: {
    Datepicker,
    MaterialMultiselect,
  },
  setup() {
    const route = useRoute();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const tab2 = ref<any>(null);
    const file = ref<any>(null);
    const multiForm = ref<any>({});
    const fileError = ref<any>("");
    const po_id = ref<any>("");
    const purchase_order = ref<any>({});
    const selectedTab = ref<any>("Details");
    const isEdit = ref<boolean>(true);
    const isAdd = ref<boolean>(true);
    const universityList = ref<Array<any>>([]);
    const formData = ref(designMaterialObjClone());
    const rules = ref({
      qty: [
        {
          required: true,
          message: "Type is required",
          trigger: "change",
        },
      ],
      material_id: [
        {
          required: true,
          message: "Material is required",
          trigger: "change",
        },
      ],
    });
    onMounted(() => {
      formData.value = designMaterialObjClone();
      if (route.params.materialId) {
        isEdit.value = false;
        isAdd.value = false;
        getItemData();
      }
    });
    const setEdit = (status) => {
      isEdit.value = status;
    };
    const getItemData = () => {
      getDesignMaterialById(route.params.materialId).then((DesignItem: any) => {
        formData.value = {
          ...DesignItem,
        };
      });
    };

    const setSelectedTab = (name) => {
      selectedTab.value = name;
      getItemData();
    };
    const checkMaxSize = () => {
      fileError.value = "";
      if (file.value?.files[0]?.size > 1024 * 500) {
        fileError.value = "Max Size Should be 500kb";
      }
    };
    const designId = computed(() => {
      return route.params.id;
    });
    const designMaterialId = computed(() => {
      return route.params.materialId;
    });
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          let reqObj: any = { ...formData.value };
          reqObj.design_id = designId.value;
          addDesignMaterial(reqObj, route.params.materialId)
            .then((responseData) => {
              loading.value = false;

              Swal.fire({
                text: getSuccess(
                  responseData,
                  "Form has been successfully submitted!"
                ),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                setTimeout(() => {
                  if (!route.params.materialId) {
                    if (window.history.length < 3) {
                      router.push({
                        name: "apps-material-design",
                        params: { id: designId.value },
                      });
                    } else {
                      router.back();
                    }
                  } else {
                    isEdit.value = false;
                    getItemData();
                  }
                }, 200);
              });
            })
            .catch((err) => {
              loading.value = false;
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };
    const handleInput = (e) => {
      console.log("input");
    };
    const universityListComputed = computed(() => {
      return universityList.value.map((item) => ({
        ...item,
      }));
    });
    const userData = computed(() => {
      return store.getters.currentUser;
    });
    const isAdmin = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });

    const onClickBack = () => {
      router.back();
    };

    return {
      isEdit,
      setEdit,
      formData,
      rules,
      formRef,
      submit,
      loading,
      file,
      moment,
      isAdd,
      handleInput,
      universityListComputed,
      isAdmin,
      po_id,
      designId,
      setSelectedTab,
      selectedTab,
      purchase_order,
      tab2,
      multiForm,
      checkMaxSize,
      fileError,
      onClickBack,
    };
  },
});

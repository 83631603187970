import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-259df5d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "card-header p-1 ribbon" }
const _hoisted_4 = { class: "card-title col-12" }
const _hoisted_5 = ["id", "placeholder"]
const _hoisted_6 = {
  key: 1,
  class: "bg-white border border-3 h-max-325px p-2 position-absolute scroll-y w-100 z-index-3"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "card-header p-1 ribbon" }
const _hoisted_9 = { class: "card-title col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.selectedItem && _ctx.showLabel)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.searchTextBoxId,
            class: _normalizeClass(`p-1 card card-bordered ${_ctx.isEdit ? 'cursor-pointer' : ''}`),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.labelClickHandle && _ctx.labelClickHandle(...args)))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.selectedItem.name), 1)
            ])
          ], 10, _hoisted_2))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        id: _ctx.searchTextBoxId,
        ref: "labelInputRef",
        class: _normalizeClass(`el-input__inner ${
          !_ctx.selectedItem || !_ctx.showLabel
            ? 'opacity-1'
            : 'opacity-0 h-1px position-absolute'
        }`),
        type: "text",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
        placeholder: _ctx.placeholderData,
        onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.searchTextBoxFocusHandle && _ctx.searchTextBoxFocusHandle(...args))),
        onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.searchTextBoxInputHandle && _ctx.searchTextBoxInputHandle(...args))),
        onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.searchTextBoxBlurHandle && _ctx.searchTextBoxBlurHandle(...args)))
      }, null, 42, _hoisted_5), [
        [_vModelText, _ctx.search]
      ]),
      (_ctx.showOption && _ctx.dropDownOption.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropDownOption, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: 'option' + index,
                onClick: ($event: any) => (_ctx.onSelectHandle(item)),
                class: _normalizeClass(`p-1 mb-2 card card-bordered ${
            _ctx.selectedItem?.id == item.id
              ? 'bg-success bg-hover-danger'
              : 'bg-hover-success '
          }`)
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(item.name), 1)
                ])
              ], 10, _hoisted_7))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
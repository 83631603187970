import moment from "moment";
import ApiService from "../services/ApiService";
interface Material {
  id: number;
  code: string;
  rate: string;
  color: string;
  purchaseCost: string;
  unit: string;
  stock: string;
  image: string;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const Materials: Array<Material> = [];

const deleteMaterial = function (item: Material) {
  return ApiService.delete(`material/${item.id}/`);
};

const addMaterial = function (item, file, id) {
  const formData = new FormData();
  delete item.image;
  for (const key in item) {
    formData.append(key, item[key]);
  }
  if (file && file[0]) {
    formData.append("image", file[0]);
  }
  if (id) {
    return ApiService.put(`material/${id}/`, formData);
  } else {
    return ApiService.post("material/", formData);
  }
};

const exportMaterialData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile(
      "export/material/?material_id=" + ids,
      "material-data"
    ).then((data) => res(data));
  });
};
const exportPDFMaterials = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/material/?material_id=" + ids).then((data) =>
      res(data)
    );
  });
};
const materialObjClone = function () {
  return {
    id: 0,
    code: "",
    rate: "",
    color: "",
    purchaseCost: "",
    unit: "",
    stock: "",
    image: "",
    created_by: ""
  };
};
const jsonToInterface = function (item: any) {
  const newItem: Material = materialObjClone();
  newItem.id = item.id;
  newItem.code = item.code;
  newItem.rate = item.rate;
  newItem.color = item.color;
  newItem.purchaseCost = item.purchaseCost;
  newItem.unit = item.unit;
  newItem.stock = item.stock;
  newItem.image = item.image;
  newItem.created_by = item.created_by;
  return newItem;
};
const getMaterialById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`material/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};


const cloneMaterial = function (id) {
  return ApiService.post(`material/${id}/clone`, { id });
};

const getMaterials = function (
  pageNumber = 1,
  searchData,
  status = "",
  user = ""
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      status: status,
      created_by: user,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`material/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const jsonToInterfaceMaterial = jsonToInterface;
const getEmptyObjOfMaterial = materialObjClone;
export {
  Material,
  getMaterials,
  deleteMaterial,
  addMaterial,
  getMaterialById,
  jsonToInterfaceMaterial,
  materialObjClone,
  exportMaterialData,
  exportPDFMaterials,
  getEmptyObjOfMaterial,
  cloneMaterial
};

export default Materials;

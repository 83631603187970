
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { uuidv4 } from "@/core/helpers/utils";
export default defineComponent({
  name: "CCSMultiselect",
  emits: [
    "open",
    "close",
    "select",
    "deselect",
    "input",
    "search-change",
    "tag",
    "update:modelValue",
    "change",
    "clear",
  ],
  props: {
    modelValue: {
      required: false,
    },
    loading: {
      required: false,
    },
    isEdit: {
      required: false,
      defualt: true,
    },
    placeholder: {
      required: false,
      defualt: "Select Item...",
    },
    options: {
      type: [Array],
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const searchTextBoxId = ref(uuidv4());
    const search = ref(null);
    const labelInputRef = ref<any>(null);
    const selectedItem = ref<any>(props.modelValue);
    const placeholderData = ref<any>(props.placeholder);
    const showOption = ref(false);
    const showLabel = ref(true);
    const isLoading = ref(props.loading);
    console.log(props.modelValue);
    const dropDownOption = computed(() => {
      return (
        props.options || [
          {
            id: 1,
            name: "Nilesh",
            code: "nil",
            description: "Text description",
          },
          {
            id: 2,
            name: "Nilesh 2",
            code: "nil2",
            description: "Text description For 2",
          },
          {
            id: 3,
            name: "Nilesh 3",
            code: "nil3",
            description: "Text description For 3",
          },
        ]
      );
    });
    onMounted(() => {
      console.log("props.modelValue", props.modelValue);
      selectedItem.value = dropDownOption.value.find(
        (item: any) => item.id == props.modelValue
      );
      console.log(
        "selectedItem.value",
        dropDownOption.value,
        selectedItem.value
      );
      showLabel.value = !props.isEdit || selectedItem.value?.id;
    });
    const onSelectHandle = (item) => {
      if (props.isEdit) {
        if (selectedItem.value?.id == item.id) {
          selectedItem.value = null;
        } else {
          selectedItem.value = item;
        }
        if (item.id == "") {
          selectedItem.value = item;
        }
        showOption.value = false;
        showLabel.value = true;
        emit("update:modelValue", selectedItem.value?.id);
        emit("select", selectedItem.value);
      }
    };
    const searchTextBoxFocusHandle = () => {
      if (props.isEdit) {
        showOption.value = true;
        showLabel.value = false;
      }
    };
    const searchTextBoxBlurHandle = () => {
      console.log("searchTextBoxBlurHandle");
      if (props.isEdit) {
        setTimeout(() => {
          showOption.value = false;
          showLabel.value = true;
        }, 300);
      }
    };
    const labelClickHandle = () => {
      if (props.isEdit) {
        showLabel.value = false;
        labelInputRef.value.focus();
      }
    };
    const searchTextBoxInputHandle = (e) => {
      emit("search-change", e.target.value);
    };
    watch(
      () => props.modelValue,
      (newVal: any, oldVal) => {
        console.log("ccs multiselect newVal.modelValue", newVal);
        selectedItem.value = dropDownOption.value.find(
          (item: any) => item.id == newVal
        );
      }
    );
    watch(
      () => props.loading,
      (newVal: any, oldVal) => {
        console.log("newVal.loading", newVal);
        isLoading.value = newVal;
      }
    );
    watch(
      () => props.isEdit,
      (newVal: any, oldVal) => {
        console.log("newVal.isEdit", newVal);
        showLabel.value = !newVal || selectedItem.value?.id;
      }
    );
    return {
      search,
      dropDownOption,
      selectedItem,
      onSelectHandle,
      showOption,
      searchTextBoxFocusHandle,
      searchTextBoxInputHandle,
      isLoading,
      searchTextBoxBlurHandle,
      showLabel,
      searchTextBoxId,
      labelClickHandle,
      labelInputRef,
      placeholderData,
    };
  },
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CcsMultiselect = _resolveComponent("CcsMultiselect")!

  return (_ctx.reRender)
    ? (_openBlock(), _createBlock(_component_CcsMultiselect, {
        isEdit: _ctx.isEdit,
        class: "w-100",
        key: 'material-multiselect',
        modelValue: _ctx.selectedItem,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItem) = $event)),
        "track-by": "value",
        label: "name",
        placeholder: "Select Material",
        options: _ctx.materialListComputed,
        searchable: true,
        onSearchChange: _ctx.searchMaterial,
        onSelect: _ctx.selectMaterial
      }, null, 8, ["isEdit", "modelValue", "options", "onSearchChange", "onSelect"]))
    : _createCommentVNode("", true)
}
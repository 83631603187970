
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import CcsMultiselect from "@/components/ccs-multiselect/index.vue";
import { getMaterials } from "@/core/data/material";
export default defineComponent({
  name: "MaterialMultiselect",
  components: {
    CcsMultiselect,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      required: false,
    },
    selectedValue: {
      required: false,
    },
    loading: {
      required: false,
    },
    isEdit: {
      required: false,
      defualt: true,
    },
    options: {
      type: [Array],
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const reRender = ref<any>(true);
    const selectedItem = ref<any>(props.modelValue);
    const materialList = ref<Array<any>>([]);
    const materialSelectedValue = ref<any>(props.selectedValue);
    const materialListComputed = computed(() => {
      return materialList.value.map((item) => ({
        ...item,
        name: item.code,
        value: item.id,
      }));
    });
    const searchMaterial = (name = "") => {
      return new Promise((res) => {
        getMaterials(1, {name}).then((resObj: any) => {
          materialList.value[0] = { ...materialSelectedValue.value };

          materialList.value.splice(
            !materialList.value[0].id ? 0 : 1,
            materialList.value.length,
            ...resObj.data.filter((item) => item.id != materialList.value[0].id)
          );
          materialList.value = [...materialList.value];
          res(true);
        });
      });
    };

    const selectMaterial = (data) => {
      materialSelectedValue.value = data;
      emit("update:modelValue", materialSelectedValue.value?.id);
    };
    watch(
      () => props.isEdit,
      (newVal: any, oldVal) => {
        searchMaterial().then(() => {
          reRender.value = false;
          setTimeout(() => {
            reRender.value = true;
          }, 100);
        });
      }
    );
    watch(
      () => props.modelValue,
      (newVal: any, oldVal) => {
        selectedItem.value = props.modelValue;
        reRender.value = false;
        setTimeout(() => {
          reRender.value = true;
        }, 100);
      }
    );
    watch(
      () => props.selectedValue,
      (newVal: any, oldVal) => {
        materialSelectedValue.value = props.selectedValue;
        searchMaterial().then(() => {
          reRender.value = false;
          setTimeout(() => {
            reRender.value = true;
          }, 100);
        });
      }
    );
    onMounted(() => {
      searchMaterial();
    });
    return {
      selectedItem,
      materialListComputed,
      searchMaterial,
      selectMaterial,
      reRender,
    };
  },
});
